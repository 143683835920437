import { Injectable } from '@angular/core';
import {
  createExecutiveStudy,
  getExecutiveStudy,
  getExecutivePlacementsByExStudyId,
  updateExecutivePlacementQuestion,
  updateExecutivePlacementAnswers,
  createExecutivePlacement,
  deleteExecutivePlacement,
  updateExecutiveStudyName,
  updateExecutiveStudyFrequency,
  updateExecutiveStudyDate,
  updateExecutiveStudyStatus,
  adminGetExecutiveStudies,
  adminGetExecutivePlacementsByExStudyId,
  insertLinkExPlacementStudy,
  searchCoreAdmin,
  getExecutiveStudySolutions,
  getExecutiveStudySolution,
  getExecutiveSolutionsByExStudyId,
  getExecutiveStudySolutionDatesByExStudyId,
  createPublishedCalculus,
  getPublishedCalculusSolutionDates,
  getPublishedCalculusSolution,
  updatePublishedCalculusEnhancedPosition,
  updatePublishedCalculusStatus,
  getPublishedCalculusCategoryLinks,
  getPublishedCalculusStudyLinksByCategory,
  insertPublishedCalculusEnhancement,
  deletePublishedCalculusEnhancement,
  getPublishedCalculusEnhancements,
  getPublishedCalculusEnhancementsPlacements,
  updatePublishedCalculusDistribution,
  getPublishedCalculusSolutionDatesByExPlacementId,
  getExecutiveSolution,
  getPublishedCalculusSolutionsByExPlacementId,
  getPublishedCalculusEnhancementsCategories,
  searchExecutiveStudies,
  searchExecutiveStudySolutions,
  getEmbedSolution,
} from '../constants/cloud-functions';
import { Study, Problem } from '../placement/builder/builder.page';
import { 
  ExecutiveStudySolution, 
  ExecutiveSolution, 
  EnhancementCategory,
  PublishedCalculusSolution,
} from '../solutions/solutions.page';
import { ExecutiveDraft } from '../admin-placement/admin-placement.page';
import { AdminExecutivePlacement } from '../admin-placement/builder/builder.page';
import { 
  Category, 
  CategoryLink, 
  StudyInCategoryLink, 
  Enhancement,
  EnhancementPlacement,
  PublishedCalculusSolutionDistribution,
  SolutionDate,
} from '../solutions/publish/publish.page';
import { EmbedSolution } from '../embed/embed.page';

@Injectable({
  providedIn: 'root',
})
export class StudyService {
  constructor() {}

  async createExecutiveStudy(executive_study_id: string, workspace_id: string) {
    await createExecutiveStudy({
      executive_study_id,
      workspace_id,
    });
  }

  async getExecutiveStudy(executive_study_id: string) {
    return getExecutiveStudy({executive_study_id}).then(
      (res) => {
        if (res) {
          const data = res.data as Study;
          return data
        }
        return [];
      }
    );
  }

  async getExecutivePlacementsByExStudyId(executive_study_id: string) {
    return getExecutivePlacementsByExStudyId({executive_study_id}).then(
      (res) => {
        if (res) {
          const data = res.data as Problem[];
          return data
        }
        return [];
      }
    );
  }

  async updateExecutivePlacementQuestion(
    executive_study_id: string, 
    executive_placement_id: string,
    question: string,
  ) {
    await updateExecutivePlacementQuestion({
        executive_study_id,
        executive_placement_id,
        question,
      });
  }

  async updateExecutivePlacementAnswers(
    executive_study_id: string, 
    executive_placement_id: string,
    answers: string,
  ) {
    await updateExecutivePlacementAnswers({
        executive_study_id,
        executive_placement_id,
        answers,
      });
  }

  async createExecutivePlacement(
    executive_study_id: string, 
    executive_placement_id: string,
    placement_number: number
    ) {
    await createExecutivePlacement({
      executive_study_id,
      executive_placement_id,
      placement_number,
    });
  }

  async deleteExecutivePlacement(executive_study_id: string, executive_placement_id: string) {
    await deleteExecutivePlacement({
        executive_study_id,
        executive_placement_id,
      });
  }

  async updateExecutiveStudyName(
    executive_study_id: string, 
    study_name: string,
  ) {
    await updateExecutiveStudyName({
        executive_study_id,
        study_name,
      });
  }

  async updateExecutiveStudyFrequency(
    executive_study_id: string, 
    frequency: string,
  ) {
    await updateExecutiveStudyFrequency({
        executive_study_id,
        frequency,
      });
  }

  async updateExecutiveStudyDate(
    executive_study_id: string, 
    date: string,
  ) {
    await updateExecutiveStudyDate({
        executive_study_id,
        date,
      });
  }

  async updateExecutiveStudyStatus(
    executive_study_id: string, 
    status: string,
  ) {
    await updateExecutiveStudyStatus({
        executive_study_id,
        status,
      });
  }

  async adminGetExecutiveStudies() {
    return adminGetExecutiveStudies().then(
      (res) => {
        if (res && Array.isArray(res.data)) {
          const data = res.data as ExecutiveDraft[];
          return data
        }
        return [];
      }
    );
  }   
  
  async adminGetExecutivePlacementsByExStudyId(executive_study_id: string) {
    return adminGetExecutivePlacementsByExStudyId({executive_study_id}).then(
      (res) => {
        if (res && Array.isArray(res.data)) {
          // Explicitly tell TypeScript that res.data is an array of objects with a map_id property
          const data = res.data as AdminExecutivePlacement[];
          // Extract map_id properties into an array of strings
          return data
        }
        // If there's no data or something went wrong, return an empty array
        return [];
      }
    );
  }  

  async insertLinkExPlacementStudy(
    executive_placement_id: string,
    study_id: string
    ) {
    await insertLinkExPlacementStudy({
      executive_placement_id,
      study_id,
    });
  }

  async searchCoreAdmin(searchString: string) {
    return searchCoreAdmin({searchString}).then(
      (res) => {
          return res;
      }
    );
  }

  async getExecutiveStudySolutions(workspace_id: string) {
    return getExecutiveStudySolutions({workspace_id}).then(
      (res) => {
        if (res) {
          const data = res.data as ExecutiveStudySolution[];
          
          const now = new Date();

          data.forEach((item) => {
            const closeDateConst = new Date(item.most_recent_placement);
            const adjustedCloseDateConst = new Date(
              closeDateConst.getTime() - closeDateConst.getTimezoneOffset() * 60000
            );
            item.past = adjustedCloseDateConst <= now;
          });
          
          // Separate the sets
          const pastSolutions = data.filter(item => item.past).sort((b, a) =>
            new Date(a.most_recent_placement).getTime() - new Date(b.most_recent_placement).getTime()
          );
          
          const nonPastSolutions = data.filter(item => !item.past).sort((b, a) =>
            new Date(b.most_recent_placement).getTime() - new Date(a.most_recent_placement).getTime()
          );
          
          // Combine the sets
          const sortedData = [...nonPastSolutions, ...pastSolutions];

          return sortedData
        }
        return [];
      }
    );
  }

  async getExecutiveStudySolution(executive_study_id: string) {
    return getExecutiveStudySolution({
      executive_study_id,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as ExecutiveStudySolution[];
          return data
        }
        return [];
      }
    );
  }

  async getExecutiveSolutionsByExStudyId(executive_study_id: string, placement_date: string,) {
    return getExecutiveSolutionsByExStudyId({
      executive_study_id,
      placement_date,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as ExecutiveSolution[];
          return data
        }
        return [];
      }
    );
  }

  async getExecutiveStudySolutionDatesByExStudyId(executive_study_id: string) {
    return getExecutiveStudySolutionDatesByExStudyId({
      executive_study_id,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as SolutionDate[];
          return data
        }
        return [];
      }
    );
  }

  async createPublishedCalculus(
    published_calculus_solution_id: string,
    published_calculus_id: string,
    solution: ExecutiveSolution
  ) {
    await createPublishedCalculus({
      published_calculus_solution_id,
      published_calculus_id,
      solution,
    });
  }

  async getPublishedCalculusSolutionDates(published_calculus_id: string) {
    return getPublishedCalculusSolutionDates({
      published_calculus_id,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as SolutionDate[];
          return data
        }
        return [];
      }
    );
  }

  async getPublishedCalculusSolution(
    published_calculus_id: string,
    published_calculus_date: string,
  ) {
    return getPublishedCalculusSolution({
      published_calculus_id,
      published_calculus_date,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as PublishedCalculusSolution;
          return data
        }
        return [];
      }
    );
  }

  async updatePublishedCalculusEnhancedPosition(
    published_calculus_id: string, 
    enhanced_position: string,
  ) {
    await updatePublishedCalculusEnhancedPosition({
      published_calculus_id,
      enhanced_position,
      });
  }

  async updatePublishedCalculusStatus(
    published_calculus_id: string, 
    status: string,
  ) {
    await updatePublishedCalculusStatus({
      published_calculus_id,
      status,
      });
  }

  async getPublishedCalculusCategoryLinks(
    published_calculus_id: string,
    executive_study_id: string,
    executive_placement_id: string,
    published_calculus_date: string,
    enhanced_position: string,
    categories: Category[],
    enhancements_hash: string,
  ) {
    return getPublishedCalculusCategoryLinks({
      published_calculus_id,
      executive_study_id,
      executive_placement_id,
      published_calculus_date,
      enhanced_position,
      categories,
      enhancements_hash,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as CategoryLink[];
          return data
        }
        return [];
      }
    );
  }

  async getPublishedCalculusStudyLinksByCategory(
    published_calculus_id: string,
    executive_study_id: string,
    executive_placement_id: string,
    published_calculus_date: string,
    enhanced_position: string,
    map_id: string,
    enhancements_hash: string,
  ) {
    return getPublishedCalculusStudyLinksByCategory({
      published_calculus_id,
      executive_study_id,
      executive_placement_id,
      published_calculus_date,
      enhanced_position,
      map_id,
      enhancements_hash,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as StudyInCategoryLink[];
          return data
        }
        return [];
      }
    );
  }

  async insertPublishedCalculusEnhancement(
    published_calculus_solution_id: string,
    published_calculus_id: string,
    executive_study_id: string,
    executive_placement_id: string,
    link_id: string,
    link_id_type: string,
    answer: string,
    category_path: string,
    question: string,
    answers: string,
  ) {
    await insertPublishedCalculusEnhancement({
      published_calculus_solution_id,
      published_calculus_id,
      executive_study_id,
      executive_placement_id,
      link_id,
      link_id_type,
      answer,
      category_path,
      question,
      answers,
    });
  }

  async deletePublishedCalculusEnhancement(
    published_calculus_solution_id: string,
    published_calculus_id: string,
    executive_study_id: string,
    executive_placement_id: string,
    link_id: string,
    link_id_type: string,
    answer: string,
  ) {
    await deletePublishedCalculusEnhancement({
      published_calculus_solution_id,
      published_calculus_id,
      executive_study_id,
      executive_placement_id,
      link_id,
      link_id_type,
      answer,
    });
  }

  async getPublishedCalculusEnhancements(
    published_calculus_solution_id: string,
    published_calculus_id: string,
    executive_study_id: string,
    executive_placement_id: string,
  ) {
    return getPublishedCalculusEnhancements({
      published_calculus_solution_id,
      published_calculus_id,
      executive_study_id,
      executive_placement_id,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as Enhancement[];
          return data
        }
        return [];
      }
    );
  }

  async getPublishedCalculusEnhancementsPlacements(
    published_calculus_solution_id: string,
    published_calculus_id: string,
    executive_study_id: string,
    executive_placement_id: string,
  ) {
    return getPublishedCalculusEnhancementsPlacements({
      published_calculus_solution_id,
      published_calculus_id,
      executive_study_id,
      executive_placement_id,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as EnhancementPlacement[];
          return data
        }
        return [];
      }
    );
  }

  async updatePublishedCalculusDistribution(
    published_calculus_id: string,
    published_calculus_date: string,
    published_calculus_solution_id: string,
    enhancements_hash: string,
    executive_placement_id: string,
  ) {
    return updatePublishedCalculusDistribution({
      published_calculus_id,
      published_calculus_date,
      published_calculus_solution_id,
      enhancements_hash,
      executive_placement_id,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as PublishedCalculusSolutionDistribution[];
          return data
        }
        return [];
      }
    );
  }

  async getPublishedCalculusSolutionDatesByExPlacementId(executive_placement_id: string) {
    return getPublishedCalculusSolutionDatesByExPlacementId({
      executive_placement_id,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as SolutionDate[];
          return data
        }
        return [];
      }
    );
  }

  async getExecutiveSolution(executive_study_id: string, placement_date: number,) {
    return getExecutiveSolution({
      executive_study_id,
      placement_date,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as ExecutiveSolution[];
          return data
        }
        return [];
      }
    );
  }

  async getPublishedCalculusSolutionsByExPlacementId(executive_placement_id: string, placement_date: number,) {
    return getPublishedCalculusSolutionsByExPlacementId({
      executive_placement_id,
      placement_date,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as PublishedCalculusSolution[];

          for (let i = 0; i < data.length; i++) {
            const orderArr = data[i].answer_order.split(',').map(num => parseInt(num.trim()));
          
            const answers = data[i].answers.split('; ');
            
            let reorderedAnswers = [];
            
            for (let j = 0; j < orderArr.length; j++) {
              reorderedAnswers[orderArr[j]] = answers[j];
            }
            
            data[i].answers = reorderedAnswers.join('; ');
      
            const popularPosition = data[i].is_popular_position.split(',');
            
            let reorderedPopularPosition = [];
            
            for (let j = 0; j < orderArr.length; j++) {
              reorderedPopularPosition[orderArr[j]] = popularPosition[j];
            }
            
            data[i].is_popular_position = reorderedPopularPosition.join(',');
            
            const popularPositionDist = data[i].alpha_dist.split(',');
          
            let reorderedPopularPositionDist = [];
            
            for (let j = 0; j < orderArr.length; j++) {
              reorderedPopularPositionDist[orderArr[j]] = popularPositionDist[j];
            }
      
            data[i].alpha_dist = reorderedPopularPositionDist.join(',');
      
            const answerOrders = data[i].answer_order.split(',');
            
            let reorderedAnswerOrders = [];
            
            for (let j = 0; j < orderArr.length; j++) {
              reorderedAnswerOrders[orderArr[j]] = answerOrders[j];
            }
            
            data[i].answer_order = reorderedAnswerOrders.join(',');
    
            data[i].isActive = data[i].status === 'Active' ? true : false;
          }
          return data
        }
        return [];
      }
    );
  }

  async getPublishedCalculusEnhancementsCategories(
    published_calculus_solution_id: string,
    published_calculus_id: string,
    executive_study_id: string,
    executive_placement_id: string,
  ) {
    return getPublishedCalculusEnhancementsCategories({
      published_calculus_solution_id,
      published_calculus_id,
      executive_study_id,
      executive_placement_id,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as EnhancementCategory[];
          return data
        }
        return [];
      }
    );
  }

  async searchExecutiveStudies(searchString: string, workspace_id: string) {
    return searchExecutiveStudies({searchString, workspace_id}).then(
      (res) => {
          return res;
      }
    );
  }

  async searchExecutiveStudySolutions(searchString: string, workspace_id: string) {
    return searchExecutiveStudySolutions({searchString, workspace_id}).then(
      (res) => {
          return res;
      }
    );
  }

  // async getEmbedSolution(
  //   embed_id: string,
  //   get_enhancements: boolean,
  // ) {
  //   return getEmbedSolution({
  //     embed_id,
  //     get_enhancements,
  //   }).then(
  //     (res) => {
  //       if (res) {
  //         const data = res.data as PublishedCalculusSolution;
  //         return data
  //       }
  //       return [];
  //     }
  //   );
  // }

  async getEmbedSolution(embed_id: string, get_enhancements: boolean,) {
    return getEmbedSolution({
      embed_id,
      get_enhancements,
    }).then(
      (res) => {
        if (res) {
          const data = res.data as EmbedSolution[];

          for (let i = 0; i < data.length; i++) {
            const orderArr = data[i].answer_order.split(',').map(num => parseInt(num.trim()));
          
            const answers = data[i].answers.split('; ');
            
            let reorderedAnswers = [];
            
            for (let j = 0; j < orderArr.length; j++) {
              reorderedAnswers[orderArr[j]] = answers[j];
            }
            
            data[i].answers = reorderedAnswers.join('; ');
      
            const popularPosition = data[i].is_popular_position.split(',');
            
            let reorderedPopularPosition = [];
            
            for (let j = 0; j < orderArr.length; j++) {
              reorderedPopularPosition[orderArr[j]] = popularPosition[j];
            }
            
            data[i].is_popular_position = reorderedPopularPosition.join(',');
            
            const popularPositionDist = data[i].alpha_dist.split(',');
          
            let reorderedPopularPositionDist = [];
            
            for (let j = 0; j < orderArr.length; j++) {
              reorderedPopularPositionDist[orderArr[j]] = popularPositionDist[j];
            }
      
            data[i].alpha_dist = reorderedPopularPositionDist.join(',');
      
            const answerOrders = data[i].answer_order.split(',');
            
            let reorderedAnswerOrders = [];
            
            for (let j = 0; j < orderArr.length; j++) {
              reorderedAnswerOrders[orderArr[j]] = answerOrders[j];
            }
            
            data[i].answer_order = reorderedAnswerOrders.join(',');
          }
          return data
        }
        return [];
      }
    );
  }
}
